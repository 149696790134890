<template>
  <div>
    <div class="toolbar">
      <div class="h-input-group">
        <input type="text" placeholder="请输入手机号或车牌" v-model="filterVal"/>
        <Button color="primary" icon="fa fa-search" @click="doSearch">查询</Button>
      </div>
    </div>
    <Table :datas="dataList" :loading="isLoading">
      <TableItem title="姓名" prop="username"/>
      <TableItem title="性别" prop="gender"/>
      <TableItem title="电话" prop="mobile"/>
      <TableItem title="车牌" prop="carNumber"/>
      <TableItem title="积分" prop="integral"/>
    </Table>
  </div>
</template>

<script>

import Member from '../../api/Member';

export default {
  name: "Member",
  data() {
    return {
      dataList: [],
      filterVal: '',
      isLoading: false
    }
  },
  methods: {
    loadList(params) {
      Member.list(params).then(({data}) => {
        this.dataList = data;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    doSearch() {
      this.loadList({search: this.filterVal})
    }
  },
  created() {
    this.loadList();
  }
}
</script>

<style scoped>

</style>
