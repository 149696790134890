<template>
  <div>
    <div class="toolbar">
      <div class="h-input-group">
        <input type="text" placeholder="请输入姓名或手机号" v-model="filterVal"/>
        <Button color="primary" icon="fa fa-search" @click="doSearch">查询</Button>
      </div>
      <div>
        <Button @click="toForm()" color="primary" icon="fa fa-plus">添加</Button>
      </div>
    </div>
    <Table :datas="dataList" :loading="isLoading">
      <TableItem title="账号" prop="username"/>
      <TableItem title="姓名" prop="nickname"/>
      <TableItem title="电话" prop="mobile"/>
      <TableItem title="角色" prop="roleType" dict="roleType"/>
      <TableItem title="操作" :width="120" align="center">
        <div class="actions" slot-scope="{data}">
          <span @click="toForm(data)" class="text-hover"><i class="fa fa-edit"></i> 编辑</span>
          <span @click="remove(data)" class="text-hover"><i class="fa fa-trash"></i> 删除</span>
        </div>
      </TableItem>
    </Table>
  </div>
</template>

<script>
import SystemUser from "../../api/SystemUser";

export default {
  name: "Administrator",
  data() {
    return {
      dataList: [],
      filterVal: '',
      isLoading: false
    }
  },
  methods: {
    loadList(params) {
      SystemUser.list(params).then(({data}) => {
        this.dataList = data;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    doSearch() {
      this.loadList({filterVal: this.filterVal})
    },
    toForm(entity) {
      this.$Modal({
        title: '用户信息',
        closeOnMask: false,
        middle: true,
        width: 400,
        component: {
          vue: (resolve) => {
            require(['./administrator/SysUserForm'], resolve);
          },
          datas: {
            entity
          }
        },
        events: {
          success: (modal) => {
            this.loadList();
            modal.close();
          }
        }
      });
    },
    remove(entity) {
      this.$Confirm('确认删除?', '系统提示').then(() => {
        SystemUser.remove(entity.id).then(({data}) => {
          this.loadList();
        });
      });
    }
  },
  created() {
    this.loadList();
  }
}
</script>

<style scoped>

</style>
