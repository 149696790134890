/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2019 soho team All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2019年11月19日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';

export default {
  list(params = {}) {
    return Ajax.get('/system/user', params);
  },
  save(params = {}) {
    if (params.id) {
      return Ajax.put('/system/user', params);
    }
    return Ajax.post('/system/user', params);
  },
  remove(id) {
    return Ajax.delete(`/system/user/${id}`);
  }
}
